import { Controller } from '@hotwired/stimulus';

import Rails from '@rails/ujs';

import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = [
    'postOfficesInput',
    'postOfficesArea',
    'postOfficesResult',
    'deliveryTypeSelect',
    'requiredDocument',

    'phoneLineDocument',
    'debitDocument',
    'agreementDocument',
    'invoiceDocument',

    'postOfficeField',

    'paymentType',

    'b2bAddressArea',
    'b2bAddressInfoArea',
    'b2bAddressInfoData',
    'b2bAddressSelect',
    'receiverDocument',
    'receiverDocumentSelect',
    'receiverPhone',

    'orderLineBillingTotalAmount',
    'orderLineAmount',
    'orderLineDepositAmount',
    'submitFormBtn',
    'receiverB2bCompanyLabel',
    'receiverName',
    'requiredDocumentationRoot',
    'receiverDocumentField',

    'externalCompany'
  ]

  static values = {
    apiSearch: String,
    isB2b: Boolean,
    addressApiSearch: String,
    addressApiSearchById: String
  }

  clearDocumentFields() {
    if(this.hasPhoneLineDocumentTarget) {
      this.phoneLineDocumentTarget.value = null;
    }
    if(this.hasDebitDocumentTarget) {
      this.debitDocumentTarget.value = null;
    }
    if(this.hasAgreementDocumentTarget) {
      this.agreementDocumentTarget.value = null;
    }
    if(this.hasInvoiceDocumentTarget) {
      this.invoiceDocumentTarget.value = null;
    }
  }

  setDocumentFieldsStatus(disabled, keepEnabled = []) {
    if(this.hasPhoneLineDocumentTarget) {
      this.phoneLineDocumentTarget.disabled = disabled && keepEnabled.indexOf("phone") === -1;
    }
    if(this.hasDebitDocumentTarget) {
      this.debitDocumentTarget.disabled = disabled && keepEnabled.indexOf("debit") === -1;
    }
    if(this.hasAgreementDocumentTarget) {
      this.agreementDocumentTarget.disabled = disabled && keepEnabled.indexOf("agreement") === -1;
    }
    if(this.hasInvoiceDocumentTarget) {
      this.invoiceDocumentTarget.disabled = disabled && keepEnabled.indexOf("invoice") === -1;
    }
  }

  setRequiredDocumentsStatus(isDisabled, keepSelectedIndex = []) {
    if(this.hasRequiredDocumentTarget) {
      this.requiredDocumentTargets.forEach((element, index) => {
        element.disabled = isDisabled;
        element.checked = keepSelectedIndex.indexOf(index) !== -1;

        if(element.checked && isDisabled) {
          const dummyInput = document.createElement("input");

          dummyInput.type = "hidden";
          dummyInput.name = element.name;
          dummyInput.value = element.value;
          dummyInput.classList.add("dummy-disabled")

          element.insertAdjacentElement('afterend', dummyInput);

        }else if(!isDisabled) {
          if(element.nextSibling && element.nextSibling.classList && element.nextSibling.classList.contains("dummy-disabled")) {
            element.nextSibling.remove()
          }
        }
      });
    }
  }

  setPaymentTypeStatus(disabled) {
    if(this.hasPaymentTypeTarget) {
      if(disabled) {
        this.paymentTypeTarget.value = "no_apply";
        this.paymentTypeTarget.dispatchEvent(new Event('change', {"bubbles": true}));

        let input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", this.paymentTypeTarget.name);
        input.setAttribute("value", this.paymentTypeTarget.value);

        this.paymentTypeTarget.parentElement.appendChild(input);
      }else{
        let input = this.paymentTypeTarget.parentElement.querySelector(`input[name='${this.paymentTypeTarget.name}']`)

        if(input) {
          input.remove();
        }
      }

      this.paymentTypeTarget.disabled = disabled;
    }
  }

  requestMapEvent(eventName, details = {}) {
    // this.dispatch(eventName, {
    //   detail: { ...details }
    // });
    const event = new CustomEvent(eventName, {
      detail: {
        ...details
      }
    });

    document.dispatchEvent(event);
  }

  updateOnPostOfficeChanged(checked, clearFields = false) {
    this.clearDocumentFields();

    if(checked) {
      this.hasB2bAddressAreaTarget && this.b2bAddressAreaTarget.classList.add('hidden');

      this.hasB2bAddressInfoAreaTarget && this.b2bAddressInfoAreaTarget.classList.remove('hidden');

      this.postOfficesAreaTarget.classList.remove('hidden');
      this.setRequiredDocumentsStatus(true, [0]);

      this.setDocumentFieldsStatus(true, ["invoice"])

      this.setPaymentTypeStatus(true);

      this.requestMapEvent('showCustomAddress', {clean: clearFields})
    }else{
      if(this.hasPostOfficeFieldTarget) {
        this.postOfficeFieldTarget.removeAttribute('value');
        this.postOfficesInputTarget.value = "";
      }

      this.hasB2bAddressAreaTarget && this.b2bAddressAreaTarget.classList.remove('hidden');
      this.hasB2bAddressInfoAreaTarget && this.b2bAddressInfoAreaTarget.classList.add('hidden');

      this.postOfficesAreaTarget.classList.add('hidden');

      this.setPaymentTypeStatus(false)

      this.setRequiredDocumentsStatus(false);

      this.setDocumentFieldsStatus(false);

      this.requestMapEvent('clearAndRestoreMap')
    }
  }

  // address_apt_or_suite
  // :
  // "123"
  // address_corner
  // :
  // "16"
  // address_line
  // :
  // "Av. 18 de Julio 1213"
  // address_locality
  // :
  // "Montevideo"
  // address_obs
  // :
  // "No tiene"
  // address_state
  // :
  // "Montevideo"
  // business_name
  // :
  // ""
  // client
  // :
  // "45000"
  // client_rut
  // :
  // "4545454545"
  // company_id
  // :
  // "TMK_FL"
  // created_at
  // :
  // "2024-02-27T23:48:10.122-03:00"
  // default
  // :
  // true
  // id
  // :
  // 50298
  // lat
  // :
  // "-34.9056513"
  // lat_lng_precision
  // :
  // null
  // lng
  // :
  // "-56.1898303"
  // name
  // :
  // "Telemarketing FL Prueba"
  // phone
  // :
  // "59894444444"
  // updated_at
  // :
  // "2024-02-27T23:48:10.122-03:00"
  // warehouse
  // :
  // "Telemarketing"
  onCustomerSelected(event) {
    const content = event.detail.content;

    this.receiverB2bCompanyLabelTarget.parentElement.classList.remove('hidden')

    if(content) {
      this.receiverNameTarget.value = content.name;
      this.receiverB2bCompanyLabelTarget.innerHTML = content.name;
      this.receiverPhoneTarget.value = content.phone;
      this.receiverDocumentTarget.value = content.client_rut;
      this.externalCompanyTarget.value = content.company_id;
    }

    this.fillB2BAddressTargetSelect(content);
  }

  initializePostOffice() {
      this.postOfficesInputTarget.addEventListener('input', this.onPostOfficeInputChanged.bind(this));
      this.postOfficesInputTarget.addEventListener('focus', this.onPostOfficeInputFocus.bind(this));
      this.postOfficesInputTarget.addEventListener('blur', this.onPostOfficeInputBlur.bind(this));
  }

  connect() {

    if(this.hasReceiverDocumentSelectTarget) {
      this.receiverDocumentTarget
    }

    if(this.hasDeliveryTypeSelectTarget) {
      this.initializePostOffice();

      if (this.deliveryTypeSelectTarget.value === 'post_office') {
        this.updateOnPostOfficeChanged(true);
      } else {
        this.postOfficesAreaTarget.classList.add('hidden');

        if(this.isB2bValue) {
          // this.requiredDocumentationRootTarget.classList.add('hidden');
          this.setRequiredDocumentsStatus(true)

          if(this.hasReceiverDocumentFieldTarget) {
            this.receiverDocumentFieldTarget.value = '';
            this.receiverDocumentFieldTarget.disabled = true;
          }
        }
      }

      this.deliveryTypeSelectTarget.addEventListener('change', (event) => {
        if(this.hasReceiverDocumentFieldTarget) {
          this.receiverDocumentFieldTarget.disabled = false;
        }

        if (event.target.value === 'post_office') {
          this.updateOnPostOfficeChanged(true, true);
        } else {
          this.updateOnPostOfficeChanged(false);

          if(this.isB2bValue) {
            // this.requiredDocumentationRootTarget.classList.add('hidden');
            this.setRequiredDocumentsStatus(true)

            if(this.hasReceiverDocumentFieldTarget) {
              this.receiverDocumentFieldTarget.value = '';
              this.receiverDocumentFieldTarget.disabled = true;
            }
          }
        }
      });
    }

    if(this.hasB2bAddressSelectTarget) {
      this.b2bAddressSelectTarget.addEventListener('change', (_) => {
        if (this.b2bAddressSelectTarget.selectedIndex > 0) {
          const valSelected = this.b2bAddressSelectTarget.options[this.b2bAddressSelectTarget.selectedIndex].dataset;

          if(!valSelected['address_line'] || !valSelected['address_locality'] || !valSelected['address_state']) {
            Swal.fire({
              title: 'Error en la dirección',
              text: `La dirección seleccionada no es válida (line: ${valSelected['address_line']}, localidad: ${valSelected['address_locality']}, state: ${valSelected['address_state']})`,
              icon: 'error',
              confirmButtonText: 'Cerrar'
            });
          }else{
            this.notifyMapAddress(valSelected);
          }
        }
      })
    }
    if(this.hasPaymentTypeTarget) {
      this.orderLineAmountTarget.addEventListener('change', (_) => {
        this.updateTotalAmount();
      });
      this.orderLineDepositAmountTarget.addEventListener('change', (_) => {
        this.updateTotalAmount();
      });

      this.paymentTypeTarget.addEventListener('change', (_) => this.checkPaymentType())

      this.checkPaymentType();
      this.updateTotalAmount();
    }

    if(this.hasSubmitFormBtnTarget) {
      this.submitFormBtnTarget.addEventListener('click', (event) => {

        let valid = true;

        if(this.hasReceiverDocumentSelectTarget) {
          if(this.receiverDocumentSelectTarget.value === 'id_card') {
            const currentDocumentId = this.receiverDocumentTarget.value;

            if(!this.validate_ci(currentDocumentId)) {
              valid = false;

              Swal.fire({
                title: 'Error en el documento',
                text: `El documento de identidad no es correcto`,
                icon: 'error',
                confirmButtonText: 'Cerrar'
              });
            }
          }
        }

        const order_lines = document.getElementById('order_lines');

        order_lines && order_lines.querySelectorAll('tr input:not([type="hidden"])')
            .forEach((l) => {
              if (!l.checkValidity()) {
                valid = false;
                l.reportValidity();
                event.preventDefault();
              }
            });

        if(this.hasB2bAddressSelectTarget) {
          if (this.b2bAddressSelectTarget.selectedIndex > 0) {
            const valSelected = this.b2bAddressSelectTarget.options[this.b2bAddressSelectTarget.selectedIndex].dataset;

            if(valSelected['address_line'] || !valSelected['address_locality'] || !valSelected['address_state']) {
              valid = false;
            }
          }
        }

        return valid;
      });
    }
  }

  // Tomado de https://github.com/picandocodigo/ci_js/blob/gh-pages/ci.js
  validation_digit(ci){
    let a = 0;
    let i = 0;
    if(ci.length <= 6){
      for(i = ci.length; i < 7; i++){
        ci = '0' + ci;
      }
    }
    for(i = 0; i < 7; i++){
      a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
    }
    if(a%10 === 0){
      return 0;
    }else{
      return 10 - a % 10;
    }
  }

  validate_ci(ci){
    ci = this.clean_ci(ci);
    let dig = ci[ci.length - 1];
    ci = ci.replace(/[0-9]$/, '');
    return (dig == this.validation_digit(ci));
  }

  random_ci(){
    let ci = Math.floor(Math.random() * 10000000).toString();
    ci = ci.substring(0,7) + this.validation_digit(ci);
    return ci;
  }

  clean_ci(ci){
    return ci.replace(/\D/g, '');
  }

  notifyMapAddress(valSelected) {
    // const showAddressEvent = new CustomEvent('showCustomAddress', {
    //   detail: {
    //     office: {
    //       address: valSelected.address_line,
    //       address_state: valSelected.address_state,
    //       address_locality: valSelected.address_locality,
    //       observation: valSelected.observations,
    //       lat: valSelected.lat,
    //       lng: valSelected.lng,
    //       corner: valSelected.address_corner,
    //       apto: valSelected.apto
    //     },
    //     keepLocality: true
    //   }
    // });

    // document.dispatchEvent(showAddressEvent);

    this.requestMapEvent('showCustomAddress', {
      office: {
        address: valSelected.address_line,
        address_state: valSelected.address_state,
        address_locality: valSelected.address_locality,
        observation: valSelected.observations,
        lat: valSelected.lat,
        lng: valSelected.lng,
        corner: valSelected.address_corner,
        apto: valSelected.apto
      },
      keepLocality: true
    });
  }

  checkPaymentType(){
    if (this.paymentTypeTarget.value === 'no_apply') {
      this.orderLineAmountTarget.setAttribute('disabled', '');
      this.orderLineDepositAmountTarget.setAttribute('disabled', '');

      this.orderLineAmountTarget.value = 0;
      this.orderLineDepositAmountTarget.value = 0;
      this.orderLineBillingTotalAmountTarget.innerText = '$ 0';

    } else {
      this.orderLineAmountTarget.removeAttribute('disabled');
      this.orderLineDepositAmountTarget.removeAttribute('disabled');
    }
  }

  updateTotalAmount() {
    const lineAmount = parseFloat(this.orderLineAmountTarget.value);
    const depositAmount = parseFloat(this.orderLineDepositAmountTarget.value);

    let totalAmount = 0;

    if (lineAmount) {
      totalAmount += lineAmount;
    }

    if (depositAmount) {
      totalAmount += depositAmount;
    }

    this.orderLineBillingTotalAmountTarget.innerHTML = `$ ${totalAmount}`;
  }

  buildAddressOptionElement(address) {
    const option = document.createElement("option");

    option.text = address.address_line;
    option.value = address.id;
    option.dataset.lat = address.lat
    option.dataset.lng = address.lng
    option.dataset.address_line = address.address_line
    option.dataset.address_state = address.address_state
    option.dataset.address_locality = address.address_locality
    option.dataset.observations = address.address_obs || ""
    option.dataset.corner = address.address_corner || ""
    option.dataset.apto = address.address_apt_or_suite || ""

    return option;
  }

  onPostOfficeInputFocus() {
    this.postOfficesResultTarget.classList.remove('hidden');
  }

  onPostOfficeInputBlur() {
    // this.postOfficesResultTarget.classList.add('hidden');
  }

  onPostOfficeElementClicked(office) {

    this.postOfficesResultTarget.classList.add('hidden');

    this.postOfficeFieldTarget.value = office.id

    if(this.hasB2bAddressInfoDataTarget) {
      this.b2bAddressInfoDataTarget.innerHTML = office.address;
    }

    // const event = new CustomEvent('showCustomAddress', {
    //   detail: {
    //     office: { ...office, ovservation: office.schedules }, readOnly: true,
    //   },
    // });
    //
    // document.dispatchEvent(event);

    this.requestMapEvent('showCustomAddress', {
      office: { ...office, ovservation: office.schedules }, readOnly: true,
    });

    this.postOfficesInputTarget.value = office.name;
  }

  fillB2BAddressTargetSelect(address) {

    const length = this.b2bAddressSelectTarget.options.length;
    for (let i = 1; i < length; i++) {
      this.b2bAddressSelectTarget.options[i].remove();
    }

    Rails.ajax({
      type: 'GET',
      accept: 'json',
      dataType: 'json',
      url: `${this.addressApiSearchValue}?client_rut=${address.client_rut}`,
      data: {
        client_rut: address.client_rut
      },
      success: (data) => {
        data.forEach((address) => {
          let element = this.buildAddressOptionElement(address);
          this.b2bAddressSelectTarget.append(element);
        });

        if(data.length === 1) {
          this.b2bAddressSelectTarget.value = data[0].id;
          this.b2bAddressSelectTarget.selectedIndex = 1;


          this.b2bAddressSelectTarget.dispatchEvent(new Event('change'));
        }
      },
    });
  }

  buildSearchElement(office) {
    let divRoot = document.createElement('div');

    divRoot.onclick = this.onPostOfficeElementClicked.bind(this, office);

    let h3 = document.createElement('h3');
    let h3Locality = document.createElement('h3');
    let h3Address = document.createElement('p');

    divRoot.classList.add('p-2', 'cursor-pointer', 'hover:border', 'rounded-md', 'hover:bg-gray-100');

    h3.classList.add('text-md', 'font-bold');
    h3.innerHTML = office.name;

    h3Locality.classList.add('text-xs', 'font-bold', 'text-gray-400');
    h3Locality.innerHTML = `${office.address_locality}, ${office.address_state}`;

    h3Address.classList.add('text-gray-400', 'truncate')
    h3Address.style.fontSize = '0.7rem'

    h3Address.innerHTML = office.address

    divRoot.append(h3);
    divRoot.append(h3Locality);
    divRoot.append(h3Address);

    return divRoot;
  }

  onFilterSearchResult(data) {
    this.postOfficesResultTarget.innerHTML = '';

    if (!data || data.length === 0) {
      this.postOfficesResultTarget.innerHTML = 'Sin resultados';
    }

    data.forEach((office) => {
      let element = this.buildSearchElement(office);
      this.postOfficesResultTarget.append(element);
    });
  }

  onPostOfficeInputChanged() {
    const { value } = this.postOfficesInputTarget;

    if (value && value.length > 2) {
      let formData = new FormData();

      // formData.append('q[name_or_address_locality_or_address_state_cont]', value);
      formData.append('q[filter]', value);

      Rails.ajax({
        type: 'POST',
        accept: 'json',
        dataType: 'json',
        url: this.apiSearchValue,
        data: formData,
        success: this.onFilterSearchResult.bind(this),
      });
    } else {
      this.postOfficesResultTarget.innerHTML = '...';
    }
  }
}
